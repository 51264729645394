import { IPage } from '@types'
import Layout from 'components/layouts/Layout'

let Page404: IPage = () => {
  return (
    <main className='flex flex-col px-4'>
      <h1 className='my-20 text-2xl xl:text-4xl text-center leading-snug'>
        Ошибка 404 <br /> Нет такой страницы
      </h1>
    </main>
  )
}

Page404.getLayout = (page) => (
  <Layout title={page.props.title}>{page}</Layout>
)

export const getStaticProps = () => ({
  props: {
    title: 'Ошибка 404 | Нет такой страницы',
  },
})

export default Page404
